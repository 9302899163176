<template>
    <div class="text-center space-y-default">
        <v-text>
            Your account is pending approval, please contact us for further assistance.
        </v-text>

        <v-button v-if="hasImpersonationToken" color="dark" class="w-full text-center" @click="stopImpersonation()">
            Stop Impersonation
        </v-button>

        <v-button v-if="!hasImpersonationToken" :to="{ name: 'auth.logout' }" color="primary" class="w-full text-center">
            Logout
        </v-button>
    </div>
</template>

<script>
export default {
    computed: {
        hasImpersonationToken() {
            return localStorage.getItem("impersonation_token");
        },
    },
    methods: {
        stopImpersonation() {
            localStorage.removeItem("impersonation_token");

            window.location.href = this.$router.resolve({ name: "users" }).href;
        },
    },
};
</script>
